import React, { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import sal from "sal.js";
import ToolData from "../../data/header.json";

const CallToAction = () => {
  const [demoVideoUrl, setDemoVideoUrl] = useState("");

  useEffect(() => {
    sal();

    // Fetch demo video URL from JSON file
    fetch("/data/video-links.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setDemoVideoUrl(data["demo-video-yotube-url"]);
      })
      .catch((error) => {
        console.error("Error fetching video URL:", error);
      });
  }, []);

  return (
    <>
      <div className="rainbow-callto-action-area">
        <div className="wrapper">
          <div className="rainbow-callto-action clltoaction-style-default rainbow-section-gap">
            <div className="container">
              <div className="row row--0">
                <div className="col-lg-12">
                  <div className="align-items-center content-wrapper">
                    <div className="inner">
                      <div className="content text-center">
                        <h2
                          className="title"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Are You Ready To Dive?
                        </h2>
                        <p
                          className="description"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="300"
                        >
                          Watch demo and contact our experienced team.
                        </p>
                        <div className="button-group text-center">
                          <Link
                            className="btn-default btn-large"
                            href={demoVideoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                            data-vbtype="video"
                          >
                            <span>
                              <i className="feather-play"></i>
                            </span>{" "}
                            Watch Demo
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*<div className="fancy-genearate-section">
            <div className="container">
              <div className="genarator-section">
                <ul className="genarator-card-group full-width-list">
                  {ToolData &&
                    ToolData.toolsItem.slice(0, 5).map((data, index) => (
                      <li key={index}>
                        <Link
                          href={data.link}
                          className="genarator-card bg-flashlight-static center-align"
                        >
                          <div className="inner">
                            <div className="left-align">
                              <div className="img-bar">
                                <Image
                                  src={data.img}
                                  width={data.wdt ? data.wdt : 40}
                                  height={40}
                                  alt="AI Generator"
                                />
                              </div>
                              <h5 className="title">{data.title}</h5>
                              <span className="rainbow-demo-btn">
                                Try It Now Free
                              </span>
                            </div>
                          </div>
                          {data.badge !== "" ? (
                            <span className="rainbow-badge-card">
                              {data.badge}
                            </span>
                          ) : (
                            ""
                          )}
                        </Link>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
                  </div>*/}
        </div>
      </div>
    </>
  );
};

export default CallToAction;
