import Header from "@/components/Header/Header";
import HeaderTop from "@/components/Header/HeaderTop/Header-Top";
import PopupMobileMenu from "@/components/Header/PopupMobileMenu";
import Home from "@/components/Home/Home";
import Service from "@/components/Service/Service";
import Context from "@/context/Context";
import Separator from "../separator";
import Timeline from "@/components/Timeline/Timeline";
import Split from "@/components/Split/Split";
import Pricing from "@/components/Pricing/Pricing";
import Accordion from "@/components/Accordion/Accordion";
import Brands from "@/components/Brands/Brands";
import CallToAction from "@/components/CallToAction/CallToAction";
import Footer from "@/components/Footer/Footer";
import Copyright from "@/components/Footer/Copyright";
import PageHead from "../Head";
import WaitList from "@/components/WaitList/WaitList";

const HomePage = () => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Home",
    "description": "Welcome to our homepage! Discover our services, explore timelines, join our waitlist, and learn more about what we offer. Get started with our comprehensive and user-friendly platform.",
    "url": "https://www.nisus-ai.com",
  };

  return (
    <>
      <PageHead
        title="Home"
        description="Welcome to our homepage! Discover our services, explore timelines, join our waitlist, and learn more about what we offer. Get started with our comprehensive and user-friendly platform."
        schemaMarkup={schemaMarkup}
      />
      <main className="page-wrapper">
        <Context>
          <Header
            headerTransparent="header-not-transparent"
            headerSticky="header-sticky"
            btnClass="btn-small round"
          />
          <Home />
          <Separator top={true} />
          {/*<WaitList />
          <Separator top={false} />*/}
          <Split />
          <Service />
          <Separator top={true} />
          <Timeline />
          <Separator top={false} />
          <CallToAction />
          <Footer />
          <Copyright />
        </Context>
      </main>
    </>
  );
};

export default HomePage;
