import React, { useState, useEffect } from "react";
import emailjs from "@emailjs/browser";

const WaitList = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false); // Loading state
  const [windowWidth, setWindowWidth] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // Button disable state

  useEffect(() => {
    if (typeof window !== "undefined") {
      setWindowWidth(window.innerWidth);
    }

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setLoading(true); // Start loading
      setIsButtonDisabled(true); // Disable button
      try {
        const templateParams = {
          user_email: email,
          message: `New waitlist request from: ${email} at ${new Date().toLocaleString(
            "en-GB",
            {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }
          )}`,
        };

        await emailjs.send(
          "service_cxdcv6i",
          "template_yi7h2wd",
          templateParams,
          "6rKuRizShiG47BAfc"
        );

        setMessage("Thank you for joining the waitlist!");
        setEmail("");
      } catch (error) {
        console.error("EmailJS Error:", error);
        setMessage("There was an error. Please try again.");
      } finally {
        setLoading(false); // Stop loading
        setIsButtonDisabled(false); // Enable button
      }
    } else {
      setMessage("Please enter a valid email address.");
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const styles = {
    outerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "30vh",
      padding: windowWidth && windowWidth <= 480 ? "50px" : "10px",
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      background: "linear-gradient(135deg, #0072ff, #077373)",
      color: "#fff",
      fontFamily: "Arial, sans-serif",
      textAlign: "center",
      padding: "20px",
      borderRadius: "30px",
      width: "100%",
      maxWidth: "700px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontSize:
        windowWidth && windowWidth <= 480
          ? "1.2em"
          : windowWidth && windowWidth <= 768
          ? "1.5em"
          : "2em",
      marginBottom: "20px",
      animation: "fadeInDown 1s ease",
    },
    form: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    input: {
      padding: "15px",
      fontSize: "1em",
      marginBottom: "15px",
      border: "none",
      borderRadius: "30px",
      width: "100%",
      boxShadow: "0 4px 6px 4px rgba(0, 0, 0, 0.15)",
      transition: "transform 0.2s ease",
      color: "#fff",
    },
    button: {
      padding: "15px 30px",
      fontSize:
        windowWidth && windowWidth <= 480
          ? "0.9em"
          : windowWidth && windowWidth <= 768
          ? "1em"
          : "1.2em",
      border: "none",
      borderRadius: "20px",
      backgroundColor: "#d6765a",
      color: "white",
      cursor: isButtonDisabled ? "not-allowed" : "pointer", 
      transition: "background-color 0.3s ease, transform 0.3s ease",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    buttonHover: {
      backgroundColor: "#fc4a1a",
    },
    spinner: {
      border: "4px solid rgba(255, 255, 255, 0.3)",
      borderRadius: "50%",
      borderTop: "4px solid #fff",
      width: "20px",
      height: "20px",
      animation: "spin 1s linear infinite",
    },
    message: {
      marginTop: "20px",
      fontSize: "1.2em",
      color: "#fff",
    },
  };

  // Global olarak CSS animasyonu eklemeniz gerekiyor
  const GlobalStyles = () => (
    <style>
      {`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
    </style>
  );

  return (
    <div style={styles.outerContainer}>
      <GlobalStyles />
      <div style={styles.container}>
        <h3 style={styles.title}>
          Join the waitlist, because good things come to those who... click!
        </h3>
        <form onSubmit={handleSubmit} style={styles.form}>
          <input
            id="waitlist_email_input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            style={styles.input}
            required
          />
          <button
            id="waitlist_send_button"
            type="submit"
            style={styles.button}
            disabled={isButtonDisabled} 
          >
            {loading ? (
              <div style={styles.spinner}></div>
            ) : (
              "Join Waitlist"
            )}
          </button>
        </form>
        {message && <p style={styles.message}>{message}</p>}
      </div>
    </div>
  );
};

export default WaitList;
