import { useEffect, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import "venobox/dist/venobox.min.css";
import bannerImg from "../../public/images/banner/banner-image-03.png";
import Separator from "@/pages/separator";

const Home = () => {
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    import("venobox/dist/venobox.min.js").then((venobox) => {
      new venobox.default({
        selector: ".popup-video",
        maxWidth: window.innerWidth >= 992 ? "50%" : "100%",
      });
    });

    // Fetch video URL from JSON file
    fetch("/data/video-links.json")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setVideoUrl(data["welcome-video-yotube-url"]);
      })
      .catch((error) => {
        console.error("Error fetching video URL:", error);
      });
  }, []);

  const FreeAccessButton = () => {
    const handleClick = () => {
      window.open("https://studio.nisus-ai.com/", "_blank", "noopener,noreferrer");
    };

    return (
      <button
        className="free-access-btn"
        name="free-access-in-landing"
        onClick={handleClick}
        style={{
          padding: '15px 30px',
          fontSize: '18px',
          fontWeight: 'bold',
          color: '#fff',
          backgroundColor: '#ff6b6b',
          border: 'none',
          borderRadius: '50px',
          cursor: 'pointer',
          position: 'relative',
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
          margin: '20px auto',
          display: 'block',
        }}
      >
        <span
          style={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          Free Access
        </span>
        <style jsx>{`
          .free-access-btn::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, #ff6b6b, #feca57);
            opacity: 0;
            transition: opacity 0.3s ease;
          }
          .free-access-btn:hover::before {
            opacity: 1;
          }
          .free-access-btn:hover {
            transform: translateY(-3px);
            box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
          }
          @media (max-width: 768px) {
            .free-access-btn {
              font-size: 16px;
              padding: 12px 24px;
            }
          }
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.10); }
            100% { transform: scale(1); }
          }
          .free-access-btn {
            animation: pulse 2s infinite;
          }
        `}</style>
      </button>
    );
  };

  return (
    <>
      <style jsx>{`
        .text-break {
          word-break: break-word;
          line-height: 1.2;
        }
      `}</style>
      <div
        className="slider-area slider-style-1 variation-default slider-bg-image bg-banner1"
        data-black-overlay="1"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="inner text-center mt--10">
                <h1 className="title display-one text-break">
                  <span>{"Building Simple"}</span>
                  <br />
                  <span className="theme-gradient" style={{display:"block"}}>{"End to End"}</span>
                  <span>{"AI Assistants"}</span>
                </h1>
                <FreeAccessButton />
              </div>
            </div>
            <div className="col-lg-10 col-xl-10 order-1 order-lg-2">
              <div
                className="frame-image frame-image-bottom bg-flashlight video-popup icon-center"
                style={{ padding: "5px", borderRadius: "10px" }}
              >
                <Image
                  style={{ opacity: 0.8, borderRadius: 10 }}
                  src={bannerImg}
                  alt="Banner Images"
                />
                <div className="video-icon">
                  <Link
                    className="btn-default rounded-player popup-video border bg-white-dropshadow"
                    style={{ boxShadow: "0 0 20px 0 rgba(0,0,0,0.1)" }}
                    href={videoUrl}
                    data-vbtype="video"
                  >
                    <span>
                      <i className="feather-play"></i>
                    </span>
                  </Link>
                </div>
              </div>
            </div>
            <Separator top={false} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;